@use "~@ey-xd/motif-react/styles/mixins" as mixin;


.ean-footer-banner {
    background-color: var(--banner-bg-color);
    padding-top: 24px;
    padding-bottom: 24px;

    .ean-footer-banner-text {
        font-size: var(--h4-font-size);
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: 400;

        @include mixin.mq(medium) {
          font-size: var(--h2-font-size);
          margin-bottom: 0;
        }
    }

    .ean-footer-button {
        width: 200px;
    }

    .ean-button {
        display: flex;
    }
}

.ean-footer {
    &.motif-footer {
        background-color: var(--footer-bg-color);
        height: auto;
        padding-bottom: 100px;

        &.ean-footer-slim {
            padding-bottom:24px;
        }

        @include mixin.mq(medium) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.ean-footer-icon-link {
    display: block;

    .ean-footer-icon {
        width: 35px;
        height: 35px;
    }
}

.ean-footer-disclaimer {
    color: var(--footer-text-color);
}


