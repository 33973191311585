@use "~@ey-xd/motif-react/styles/mixins" as mixin;
@use "../styles/easings" as easings;


.motif-modal-root {
  opacity: 0;
}

.ean-app {
  visibility: hidden;
  opacity: 0;
}


.ean-app-loaded {
  .ean-app {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s easings.$ease-out-quint;
  }

  .motif-modal-root {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s easings.$ease-out-quint;

    .ean-modal {
      &.motif-modal {
        animation: 
          slide-in .75s easings.$ease-out-quint, 
          fade-in .5s easings.$ease-out-quint;
      }
    }
  }
}



.ean-progress-loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ean-content-container {
  &.motif-container {
    padding: 16px 20px 60px;
    min-height: calc(100vh - 515px);

    @include mixin.mq(medium) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mixin.mq(large) {
      padding: 16px 16px 80px;
    }
  }
}

.ean-pagination-container {
  display: flex;
  padding: 48px 0 0;
  justify-content: center;
  height: 32px;
  opacity: 0;
  animation: fade-pagination-in .5s easings.$ease-out-quint .25s forwards;
}

@keyframes fade-pagination-in {
  from {opacity: 0; transform: translateY(35px);}
  to {opacity: 1; transform: translateY(0);}
}

.ean-no-results-container {
  display: block;
  margin: 16px 0 0 12px;
 
  h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
  }
}

// Hero Title
.ean-title {
  color: var(--hero-text-color);
  font-weight: var(--weight-regular);
  font-size: var(--h2-font-size);
  margin: 0;
  margin-bottom: 12px;

  @include mixin.mq(medium) {
    font-weight: var(--weight-light);
    font-size: 3rem;
    margin-top: 8px;
    margin-bottom: 56px;
  }
}

// Search Input Component
.ean-search-input {
  position: relative;

  &.motif-search-input-wrapper {
    .motif-input {
      color: var(--form-element-color);
      padding-left: 0;
      padding-right: 0;
    }

    .motif-icon-button {
      color: var(--form-element-color);
      right: 0;
  
      &:hover {
        background-color: transparent;
        color: var(--form-element-hover-color);
      }

      &:focus {
        background-color: var(--share-btn-hover-color);
      }
    }
  }

  .motif-typeahead-screen-reader {
    display: none;
  }
}

// Tab Nav Component
.ean-tab-nav {
  position: relative;
  margin-top: 16px;
  
  &.motif-tab-navigation-horizontal {
    border: none;
  }

  &.motif-tab-navigation {
    .motif-tab-button {
      @extend .motif-main-navigation !optional;

      border-bottom-width: 3px;

      &:focus {
        outline-color: var(--tab-accent-color);
      }

      &:hover:not([disabled]) {
        text-shadow: none;
        box-shadow: 0 1px 0 0 var(--tab-accent-color);
        border-bottom-color: var(--tab-accent-color);
        color: var(--tab-accent-color);
      }

      &.motif-active {
        @extend .motif-main-navigation-active !optional;
      }
    }
  }
}

// Tablet & Mobile Filter
.ean-subnav-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;

  @include mixin.mq(medium) {
    margin-top: 20px;
  }

  .ean-dropdown-menu {
    .ean-dropdown-trigger {
      color: var(--dropdown-content-color); 

      &:hover {
        color: var(--dropdown-content-hover-color);
      }

      .motif-icon {
        display: flex;
        align-items: center;
      }
    } 

    @include mixin.mq(large) {
      display: none;
    }
  }
}

.ean-dropdown-menu button.motif-icon-button  {
  color: var(--dropdown-content-color);
  display: inline-flex;
  align-items: center;

  svg {
    color: var(--dropdown-content-color);
  }

  @include mixin.mq(large) {
    display: none;
  }
}

.ean-dropdown-reset-wrapper {
  border-bottom: 1px solid var(--dropdown-border-color);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  .motif-text-button:not([disabled]) {
    color: var(--dropdown-content-color);
  }

  .motif-icon-button.ean-close-filter-button {
    margin-right: 24px;

    &:focus {
      background-color: var(--share-btn-hover-color);
    }

    .motif-icon svg  {
      color: var(--dropdown-content-color);
    }
  }

  .ean-dropdown-focus-wrapper {
    display: inline-flex;
  }

  .ean-reset-button.motif-text-button {
    padding-left: 12px;
    margin-bottom: 0;
    font-weight: var(--weight-bold);
    

    &:hover {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  
  @include mixin.mq(medium) {
    padding: 0;
  }
  
}

.motif-theme-custom .motif-dropdown-menu-portal.ean-dropdown-menu {
  color: var(--dropdown-content-color);
  background-color: var(--btn-primary-alt-bg-color);
  height: 100vh;
  width: 100vw;
  line-height: 22px;
  overflow-y: scroll;
  scroll-padding-right: 0;

  .ean-dropdown-sm-filter {
    padding-left: 20px;
  }

  .ean-filter-sm-options {
    min-height: calc(100vh - 172px);
    overflow-y: scroll;

    @include mixin.mq(medium) {
      min-height: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .motif-dropdown-arrow {
    display: none;
  }

  .motif-form-field {
    padding: 20px;
    margin-bottom: 0;
  }

  .ean-apply-sm-filter {
    border-top: 1px solid var(--dropdown-border-color);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    bottom: 0;
    position: sticky;
    height: 122px;
    background-color: var(--btn-primary-alt-bg-color);
    padding: 16px 20px 60px;
    align-items: flex-end;

    .motif-button-primary-alt {
      border: 1px solid var(--dropdown-content-color);
      width: 100%;
      height: 44px;
    }
  }

  @include mixin.mq(medium) {
    height: auto;
    width: auto;
    margin-top: 8px;
    overflow-y: visible;
    padding-bottom: 10px;

    .ean-dropdown-sm-filter,
    .ean-close-filter-button,
    .ean-apply-sm-filter {
      display: none;
    }

    .ean-filter-sm-options {
      height: auto;
      overflow-y: visible;
    }
  
    .motif-form-field {
      padding: 12px 32px 12px 12px;
    }
  
  }

  @include mixin.mq(large) {
    display: none;
  }
}

// Added to preserve existing Motif styles
@media (max-width: 767px) {
  .motif-theme-custom .motif-dropdown-menu-portal.ean-dropdown-menu {
    transform: none !important;
    position: fixed !important;
  }
}


// Desktop Filter Layout
.ean-filter-hide {
  display: none;
  margin-top: 16px;

  @include mixin.mq(large) {
    display: block;

    .ean-filter-title {
      font-weight: var(--weight-light);
    }

    .ean-reset-button {
      &.motif-text-button {
        font-size: var(--font-size-base);
        margin-bottom: 16px;
        height: 24px;
    
        &:hover {
          margin-bottom: 16px;
        }
      }
    }
  }
}

// 404 Page
.ean-404-wrapper {
  min-height: calc(100vh - 140px);
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  

  .ean-404-header {
    @extend .ean-title;

    color: var(--body-color);

    @include mixin.mq(medium) {
      margin-bottom: 32px;
    }
  }

  p {
    margin-bottom: 24px;
  }

  .ean-404-link {
    margin: 16px auto 0;
    display: block;
    text-decoration: none;
    max-width: 200px;

    .ean-404-button {
      min-width: 200px;
    }

    @include mixin.mq(medium) {
      margin: 16px 8px 8px;
      display: inline-block;
    }
  }
}
