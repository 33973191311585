$motif-font-path: '~@ey-xd/motif-react/assets/fonts';

@import "~@ey-xd/motif-react/styles";
@import "./styles/eanTheme";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow-y: hidden;
  overflow-x: hidden;
}

.motif-col-xs-12 {
  width: 100%;
}

.hide-for-accessibility {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.ean-reset-button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}
