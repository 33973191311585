body[motif-theme="custom"] {
    // motif-overrides    
    --form-element-bg-color: transparent;
    --form-element-hover-bg-color: transparent;
    --form-element-border-color: #{$white};
    --form-element-placeholder-color: #{$grey-300};

    /* custom new variables */
    --form-element-color: #{$white};
    --form-element-hover-color: #{$grey-300};

    // project specific variable
    --hero-text-color: #{$white};
    --hero-bg-color: #{$grey-600};
    --tab-accent-color: #{$grey-300};
    --avatar-bg-color: #{$grey-300};
    --footer-bg-color: #{$grey-550};
    --footer-text-color: #{$white};
    --banner-bg-color: #{$yellow-400};
    --dropdown-border-color: #{$grey-400};
    --dropdown-content-color: #{$white};
    --dropdown-content-hover-color: #{$grey-300};
    --modal-footer-bg-color: #{$white};
    --share-btn-hover-color: #{$grey-400};
    
}
