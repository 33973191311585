.motif-checkbox.ean-dropdown-checkbox {
  .motif-checkbox-custom {
    background-color: var(--btn-primary-alt-bg-color);
    border: 1px solid var(--dropdown-content-color);
  }

  &.motif-checkbox-checked .motif-checkbox-custom {
    background-color: var(--dropdown-content-color);
    border: 1px solid var(--dropdown-content-color);

    .motif-checkbox-checked-icon svg {
      fill: var(--btn-primary-alt-bg-color);
    }
  }

}