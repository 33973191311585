@use "~@ey-xd/motif-react/styles/mixins" as mixin;
@use "../../styles/easings" as easings;

.ean-modal-btn-wrapper {
    text-decoration: none;
    width: 50%;
  
    &:nth-of-type(2) {
      margin-left: 16px;
    }

    .motif-button {
      width: 100%;
    }

    @include mixin.mq(medium) {
      width: auto;

      .motif-button {
        padding-left: 36px;
        padding-right: 36px;
      }
    }
  }

  .ean-modal {
    &.motif-modal {
        animation: 
        slide-in .75s easings.$ease-out-quint, 
        fade-in .5s easings.$ease-out-quint;
    }
  }

  .motif-modal-overlay {
    animation: fade-in .25s easings.$ease-out-quint
  }

  @keyframes slide-in{
    from {
        transform: translateY(100px);   
    }

    to {
        transform: translateY(0);
    }
  }

  @keyframes fade-in{
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
  }

  .ean-modal-focus-wrapper {
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  .ean-modal-catcher {
    position:fixed;
    background: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }