@use "../../styles/easings" as easings;


@mixin delay($number, $value, $speed) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      animation: fade-card-in (#{$i * $speed + .5s}) easings.$ease-out-expo (#{$i * $value}) forwards;
    }
  }
}

.ean-card-wrapper {
  opacity: 0;

    @include delay(100, 0.03s, 0.05s);
}

// Card Nav Component
.ean-card {
    width: 100%;
    height: 140px;
    cursor: pointer;
  
    &.motif-card {
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0; 
    }
  }

.ean-card-button {
  width: 100%;
  margin-top: 20px;
  transition: box-shadow .5s easings.$ease-out-expo;

  &:focus, &:hover {
    outline: none;
    box-shadow:  var(--card-box-shadow-hover);
    transition: box-shadow .75s easings.$ease-out-expo;

    .ean-card {
      box-shadow:  none;
    }
  }
}
  
  .ean-card-body {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    height: 140px;
    width: 100%;
    justify-content: flex-start;
  }
  
  .ean-card-avatar {
    width: 80px;
    min-width: 80px;
    height: 80px;
    background-color: var(--avatar-bg-color);
    border-radius: 40px;
    overflow: hidden;
    flex: 0 0 80px;
  }
  
  .ean-card-description {
    padding-left: 16px;
    padding-right: 32px;
    text-align: left;
  }

  .ean-card-share {
    right: 32px;
    bottom: 16px;
    position: absolute;
  }
  
  .ean-card-content {
    margin-top: 0;
    margin-bottom: 4px;

    &-title {
      margin-bottom: 0;
    }

    &-name {
      font-weight: 800;
      font-size: var(--h5-font-size);
      color: var(--body-color);
    }
  }

  @keyframes fade-card-in {
    from {opacity: 0; transform: translateY(25px);}
    to {opacity: 1; transform: translateY(0);}
}